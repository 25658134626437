.aboutPage_intro {
  background-color: $black;
  bottom: 0;
  left: 0;
  padding: 92px 0px;

  @media only screen and (max-width: 768px) {
    padding: 40px 0px;
  }
  .serviceDetail_local_list_bullet {
    display: flex;
    align-content: "center";
    justify-content: "center";
    background-color: "#abdb23";
    border-radius: "100%";
    width: "30px";
    height: "30px"
  }
  .intro {
    min-height: 45vh;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    h1 {
      font-style: normal;
      font-weight: 400;
      font-size: 48px;
      line-height: 72px;
    }
    @media only screen and (max-width: 768px) {

      h1 {
        font-style: normal;
        font-weight: 400;
        font-size: 46px;
        line-height: 52px;
        z-index: 995;
      }
    }
  }

  h1 {
    font-family: 'Ubuntu';
    font-style: normal;
    font-weight: 400;
    font-size: 48px;
    line-height: 72px;

    text-align: center;
    color: #FFFFFF;
  }
  position: relative;
  .fade_logo {
    z-index: 1;
    height: 100%;
    position: absolute;
    right: 0;
    bottom: 0;
  }
  /* background-color: $black;
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding-top: $headerHeight;
  z-index: 1;
  position: relative;
  .fade_logo {
    z-index: 1;
    height: 100%;
    position: absolute;
    right: 0;
    bottom: 0;
  }
  .intro {
    min-height: 45vh;
    display: flex;
    align-items: center;
    position: relative;
    overflow: hidden;
  }

  h1 {
    font-family: 'Ubuntu';
    font-style: normal;
    font-weight: 400;
    font-size: 48px;
    line-height: 72px;

    text-align: center;
    color: #FFFFFF;
  } */
}

.aboutPage_why_us {
  min-height: 70vh;
  padding-top: 80px;
  padding-bottom: 80px;
  h5 {
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 36px;
    /* or 150% */

    display: flex;
    align-items: center;
  }
  h4 {
    font-style: normal;
    font-weight: 400;
    font-size: 32px;
    line-height: 32px;
  }
  p {
    font-style: normal;
    font-weight: 300;
    font-size: 20px;
    line-height: 36px;
  }
  .green_bullet {
    img {
      width: 100%;

    }
    width: 70px;
    height: 70px;
    /* background-color: $green_lighter; */
    border-radius: 100%;
  }
  @media only screen and (max-width: 991px) {
    .green_bullet {
      width: 55px;
      height: 55px;
    }
  }
  @media only screen and (max-width: 768px) {
    p {
      font-style: normal;
      font-weight: 300;
      font-size: 22px;
      line-height: 32px;
    }
    .green_bullet {
      margin-top: 5px;
      width: 55px;
      height: 55px;
      border-radius: 100%;
    }
  }
  @media only screen and (max-width: 390px) {
    .green_bullet {
      width: 45px;
      height: 45px;
    }
  }
}
.aboutPage_have_an_idea {
  padding-bottom: 80px;
  .row {
    padding: 70px 0px;
  }
  h1 {
    font-style: normal;
    font-weight: 300;
    font-size: 40px;
    line-height: 60px;
    /* or 150% */

    display: flex;
    align-items: center;
    text-align: center;

    color: #FFFFFF;
  }
}
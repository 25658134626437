@keyframes rollDownMenu {
  from {
    transform: translateY(-200px);
  }
  to {
    transform: translateY(-0px);
  }
}

.header {
  position: absolute;
  z-index: 999;
  top: 0;
  left: 0;
  padding-top: 20px;
  padding-bottom: 10px;
  animation: rollDownMenu 0.6s ease-out;
  animation-delay: 1.2s;
  /* background: rgba($color: #2C2C2C, $alpha: .2); */
  @media only screen and (max-width: 991px) {
    background-color: $black;
    position: fixed;
  }
  .container {
    $paddingSides: 60px;
    /* width: calc(100vw - 120px); */
    z-index: 100;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: $headerHeight;

    /* padding-left: $paddingSides;
    padding-right: $paddingSides; */
    .logo_wrap {
      height: 40px;
      display: flex;
      align-content: center;
      justify-content: space-between;
      .logo {
        height: 100%;
        cursor: pointer;
      }
      .menu_icon {
        display: none;
      }
    }
    .list_wrap {
      margin-top: 20px;
      display: flex;
      align-items: flex-start;

      ul {
        list-style: none;
        color: #ffffff;
        display: flex;
        gap: 32px;
        margin-left: -32px;
        li {
          cursor: pointer;
          @keyframes selectorAnimation {
            0% {
              width: 0px;
            }
            100% {
              width: 25px;
            }
          }
          .selector {
            height: 5px;
            width: 25px;
            border-radius: 8px;
            background-color: $green_darker;
            animation: selectorAnimation 0.5s ease-in-out;
          }
        }
      }
    }
    .button_wrap{
      a {
        text-decoration: none;
        color: $black;
      }
    }
    @media only screen and (max-width: 991px) {
      background-color: $black;
      /* height: 370px; */
      overflow: hidden;
      align-items: flex-start;
      flex-direction: column;
      justify-content: flex-start;
      .logo_wrap {
        width: 100%;
        margin-bottom: 20px;
        .burger_wrap_open {

          transform: rotate(180deg);
        }
        .burger_wrap_close {
        }
        .burger_wrap {
          transition: 0.3s;
          display: flex;
          gap: 50px;
          position: absolute;
          right: -90px;
          .menu_icon {
            display: block;
          }
        }
      }
      .list_wrap {
        display: flex;
        width: 100%;
        justify-content: flex-start;
        ul {
          flex-direction: column;
          li {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
          }
        }
      }
      .button_wrap {
        width: 100%;
        display: flex;
        justify-content: flex-end;
        .button {
          width: 100% !important;
          justify-content: center;
        }
      }
    }
  }
}
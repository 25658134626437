@import url('https://fonts.googleapis.com/css2?family=Dancing+Script&family=Exo+2:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Kanit:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Montserrat:wght@300;400;500;600;700;800;900&family=Open+Sans:wght@600;700&family=Righteous&family=Ubuntu:wght@300;400;500;700&display=swap');

@import "./variables.scss";

@import "./components/global/header.scss";
@import "./components/global/footer.scss";
@import "./components/pages/home.scss";
@import "./components/pages/about.scss";
@import "./components/pages/services.scss";
@import "./components/pages/contact.scss";

html {
  scroll-behavior: smooth;
  max-width: 100% !important;
  overflow-x: hidden !important;
}

body {
  margin: 0;
  max-width: 100% !important;
  overflow-x: hidden !important;
  max-width: 100vw;
  /* font-family: 'Ubuntu', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
   'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  font-family: 'Ubuntu';
  &::-webkit-scrollbar{
    display: none;
  }
  ::-moz-selection { /* Code for Firefox */
    color: $green_lighter;
    background: $black;
  }

  ::selection {
    color: $green_lighter;
    background: $black;
  }
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: -light;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.loading_screen_hide_animation {
  animation: logoAnimation_wrap_1 0.5s ease-in;
}
.loading_screen {
  background-color: $black;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    width: 80px;
    animation: logoAnimation_1 0.7s ease-out;
  }
}
@keyframes logoAnimation_wrap_1 {
  from {
    transform: translateY(0vh);
  }
  to {
    transform: translateY(-150vh);
  }
}
@keyframes logoAnimation_1 {
  0% {
    transform: scale(0);
  }
  60% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}
.defaultLayout {
  overflow-x: hidden !important;
  /* padding: 0px 60px; */
}
.secondary_button {
  background-color: #ffffff !important;
  border: 2px solid $green_lighter !important;
  &:hover {
    background-color: #b6ed207c !important;
  }
  &:focus {
    background-color: #b6ed20a7 !important;
  }
  &:active {
    background-color: #8EB916 !important;
  }
  &:disabled {
    opacity: 0.5;
  }
}
.hide-on-phone {
  @media only screen and (max-width: 991px) {
    display: none;
  }
}

/* .mobile-only {
  @media only screen and (min-width: 991px) {
    display: flex;
  }
  display: none !important;
} */
.button{
  cursor: pointer;
  transition: 0.3s;
  background-color: $green_lighter;
  border: none;
  outline: none;
  color: $black;
  font-family: 'Ubuntu';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 21px;
  border-radius: 100px;
  padding: 16px 32px;
  display: flex;
  gap: 8px;
  img {
    height: 24px;
  }
  &:hover {
    background-color: #A8DA1B;
  }
  &:focus {
    background-color: #8EB916;
  }
  &:active {
    background-color: #8EB916;
  }
  &:disabled {
    opacity: 0.5;
  }
}
.icon_button {
  background-color: $green_lighter;
  cursor: pointer;
  color: $black;
  width:  60px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100px;
  &:hover {
    background-color: #A8DA1B;
  }
  &:focus {
    background-color: #8EB916;
  }
  &:active {
    background-color: #8EB916;
  }
  &:disabled {
    opacity: 0.5;
  }
}
.green-text-darker {
  color: $green_darker;
}
.green-text-lighter {
  color: $green_lighter;
}
.bg-black {
  background-color: $black;
}
.bg-red {
  background-color: red;
}
.bg-green {
  background-color: $green_lighter;
}
.scroll_up_button {
  background-color: $green_lighter;
  border-radius: 100%;
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  bottom: 15px;
  z-index: 999;
  right: 15px;
  transition: 0.3s;
  transform: translateX(100px);
  cursor: pointer
}
.page_animation {
  animation: PageAnimation 0.3s ease-out;
}
@keyframes PageAnimation {
  from {
    /* transform: translateX(-100vw); */
    opacity: 0.4;
    background-color: $black;
  }
  to {
    /* transform: translateX(0vw); */
    opacity: 1;
  }
}
.privacy_page {
  background-color: $black;
}
.privacyPage_intro {
  background-color: $black;
  bottom: 0;
  left: 0;
  padding: 92px 0px;
  h1 {
    z-index: 995;
    font-family: 'Ubuntu';
    font-style: normal;
    font-weight: 400;
    font-size: 48px;
    line-height: 72px;
    padding-left: 5px;

    color: #FFFFFF;
  }
  position: relative;
  .fade_logo {
    z-index: 1;
    height: 100%;
    position: absolute;
    right: 0;
    bottom: 0;
  }

  .intro {
    display: flex;
    align-items: flex-end;
    justify-content: flex-start;
    overflow: hidden;
  }
}
.cursor-pointer {
  cursor: pointer;
}
.privacyPage_content {
  background-color: $black;
  min-height: 60vh;
  color: #FFFFFF;
}
.cookies_banner {
  background-color: #ffffff;
  z-index: 999;
  height: auto;
  position: fixed;
  border-radius: 16px;
  bottom: 20px;
  max-width: 1000px;
  -webkit-box-shadow: 2px 3px 8px #d5d5d5;
  -moz-box-shadow: 2px 3px 8px #d5d5d5;
  box-shadow: 2px 3px 8px #d5d5d5;
  @media only screen and (max-width: 768px) {
    .button {

    }
  }
  .cookies-more-button {
    color: #B7ED20 !important;
    cursor: pointer;
    transition: 0.3s;
    border: none;
    background: none;
    text-decoration: underline !important;
    &:hover {
      color: #8EB916;
    }
  }
  a {
    color: #B7ED20 !important;
    cursor: pointer;
    transition: 0.3s;
    text-decoration: underline !important;
    &:hover {
      color: #8EB916;
    }
  }
}


.fade_in_animation_class {
  animation: fadeInAnimation 3s;
}
.animation_duration_1500 {
  animation-duration: 1500ms !important;
}
.animation_duration_1000 {
  animation-duration: 1000ms !important;
}
.animation_duration_750 {
  animation-duration: 750ms !important;
}
.animation_duration_500 {
  animation-duration: 500ms !important;
}
.animation_duration_250 {
  animation-duration: 250ms !important;
}
.slide_from_left_animation_class {
  animation: slideFromLeftAnimation 2s ease-in-out;
}
.slide_from_right_animation_class {
  animation: slideFromRightAnimation 2s ease-out;
}
.slide_from_right_animation_class_intro {
  animation: slideFromRightAnimationIntro 2s ease-out;
}

@keyframes fadeInAnimation {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes slideFromLeftAnimation {
  from {
    transform: translateX(-600px);
  }
  to {
    transform: translateX(0px);
  }
}
@keyframes slideFromRightAnimation {
  from {
    transform: translateX(600px);
  }
  to {
    transform: translateX(0px);
  }
}
@keyframes slideFromRightAnimationIntro {
  from {
    transform: translateX(600px);
  }
  to {
    transform: translateX(80px);
  }
}
.contactPage_intro {
  background-color: $black;
  bottom: 0;
  left: 0;
  padding: 92px 0px;
  .title {
    font-family: 'Ubuntu';
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 36px;
    color: #A4CF22;
  }
  .intro {
    min-height: 50vh;
    padding-top: 50px;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    overflow: hidden;
  }
  .phone, .email {
    font-style: normal;
    font-weight: 400;
    font-size: 52px;
    z-index: 995 !important;
    position: relative;
    line-height: 78px;
    @media only screen and (max-width: 768px) {
      font-size: 28px;
      line-height: 40px;
    }
    a {
      text-decoration: none;
      color: #ffffff;
      transition: 0.3s;
      &:hover {
        color: $green_darker;
      }
    }
  }
  h1 {
    font-family: 'Ubuntu';
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 36px;
    color: $green_darker;
  }
  h3, h5 ,h4 {
    color: #ffffff;
  }
  .name {
    color: $green_darker;
  }
  h6 {
    color: #ffffff;
    z-index: 995 !important;
    font-size: 20px;
    position: relative;
  }
  position: relative;
  .fade_logo {
    z-index: 1;
    height: 100%;
    position: absolute;
    right: 0;
    bottom: 0;
    z-index: 1 !important;
  }

}
/*
.contactPage_types {
  min-height: 70vh;
  padding-top: 80px;
  padding-bottom: 80px;
  color: #FFFFFF;
  background-color: $black;

}
.contactPage_have_an_idea {
  padding-bottom: 80px;
  .row {
    padding: 70px 0px;
  }
  h1 {
    font-style: normal;
    font-weight: 300;
    font-size: 40px;
    line-height: 60px;

    display: flex;
    align-items: center;
    text-align: center;

    color: #FFFFFF;
  }
} */
@import "../cards/introCard.scss";
@import "../cards/aboutUsCard.scss";
@import "../cards/servicesCard.scss";
@import "../cards/clientReviewCard.scss";


.homePage {
  /* background-image: url("../../../images/image44.png");
  background-repeat: no-repeat;
  background-size: 100% 620px; */
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding-top: $headerHeight;
  .background_image_wrap {
    position: absolute;
    left: 0;
    top: 0;
    width: 100vw;
    height: 620px;
    @media only screen and (max-width: 992px) {
      height: 580px;
    }
    @media only screen and (max-width: 767px) {
      height: 490px;
    }
    @media only screen and (max-width: 388px) {
      height: 550px;
    }
    @media only screen and (max-width: 366px) {
      height: 550px;
    }
    /* background-image: url("../../../images/image44.png"); */
    background-size: cover;                      /* <------ */
    background-repeat: no-repeat;
    background-position: center center;
    z-index: 1;
  }
  .background_image {
    position: absolute;
    left: 0;
    top: 0;
    object-fit:scale-down;
    height: auto;
    max-height: 620px;
    width: auto;
    min-width: 100%;
    z-index: 1;
  }
}


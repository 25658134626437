.clientReviewCard {
  padding-top: 60px;
  padding-bottom: 120px;
  .bottom_arrows_wrap {
    .left {
      display: none;
    }
  }
  @media only screen and (max-width: 768px) {
    padding-top: 30px;
    padding-bottom: 60px;
    .left_arrow {
      display: none !important;
    }
    .bottom_arrows_wrap {
      margin-top: 15px;
      display: flex;
      justify-content: space-between !important;
      .left {
        display: flex;
      }
    }
  }
  .title {
    text-align: center;
    font-style: normal;
    font-weight: 400;
    font-size: 48px;
    line-height: 72px;
    /* or 150% */

    display: flex;
    align-items: center;
    @media only screen and (max-width: 768px) {
      font-size: 40px;
      line-height: 50px;
    }
  }
  .arrow {
    background-color: $green_lighter;
    width: 50px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 100%;
    cursor: pointer;
    transition: 0.3s;
    &:hover {
      background-color: #A8DA1B;
    }
    &:focus {
      background-color: #8EB916;
    }
    &:active {
      background-color: #8EB916;
    }
    &:disabled {
      opacity: 0.5;
    }
  }
  .element_wrap {
    padding: 0px 180px;
    display: flex;
    /* overflow-x: hidden; */

    @media only screen and (max-width: 768px) {
      padding: 0px 15px;
    }
  }
  @keyframes elementLoadAnimation {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  .element {
    position: relative;
    .quotes_left {
      width: 30px;
      transform: translateX(-35px);
      @media only screen and (max-width: 768px) {
        transform: translateX(-15px);
      }
    }
    .quotes_right {
      width: 30px;
      position: absolute;
      right: 0;
      transform: translateX(35px);
      @media only screen and (max-width: 768px) {
        transform: translateX(15px);
      }
    }
    .text, .sign {
      animation: elementLoadAnimation 1s;
    }
    .text {
      font-style: normal;
      font-weight: 300;
      font-size: 20px;
      line-height: 30px;
      /* or 150% */

      display: flex;
      align-items: center;
    }
    .sign {
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 24px;
      /* or 150% */

      display: flex;
      align-items: center;

      /* color: #83AD0C; */
    }
  }
}
.aboutUs_content {
  padding-top: 210px;
  padding-bottom: 70px;
  @media only screen and (max-width: 768px) {
    padding-top: 130px;
    padding-bottom: 70px;
  }
  .highlight_text {
    color: #A4CF22;
    font-style: normal;
    font-weight: 400;
    font-size: 52px;
    line-height: 78px;
  }
  .main_text {
    font-style: normal;
    font-weight: 300;
    font-size: 40px;
    line-height: 60px;
    padding-right: 60px;
    @media only screen and (max-width: 768px) {
      font-size: 28px;
      line-height: 42px;
      margin-top: 15px;
      padding-right: 0px;
    }
  }
  .additional_text {
    font-style: normal;
    font-weight: 300;
    font-size: 24px;
    line-height: 36px;
    margin-top: 30px;
    padding-right: 60px;
    @media only screen and (max-width: 768px) {
      font-size: 20px;
      line-height: 30px;
      padding-right: 0px;
    }
  }
  .button_wrap {
    @media only screen and (max-width: 768px) {
      display: flex;
      justify-content: center;
      .button {
        width: 100%;
        display: flex;
        justify-content: center;
      }
    }

  }
  .experience_box {
    @media only screen and (max-width: 768px) {
      text-align: center;
      margin-top: 40px;
    }
    h1 {
      color: $green_darker;
      font-style: normal;
      font-weight: 400;
      font-size: 52px;
      line-height: 78px;
    }
    h3 {
      font-style: normal;
      font-weight: 300;
      font-size: 20px;
      line-height: 30px;
    }
  }
}
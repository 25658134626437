.footer {
  background-color: $green_lighter;
  position: static;
  bottom: 0;
  left: 0;
  padding: 92px 0px;
  @media only screen and (max-width: 768px) {
    padding: 45px 0px;
  }
  .title_text {

    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 36px;
    padding-bottom: 30px;
  }
  z-index: 50;
  .logo_wrap {
    z-index: 995;
    /* display: flex;
    align-items: center; */
    img {
      /* width: 250px; */
      width: 85%;
    }
    @media only screen and (max-width: 768px) {
      text-align: center;
      img {
        /* width: 250px; */
        width: 75%;
      }
    }
  }
  h1 {
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 36px;

    padding-bottom: 30px;

    @media only screen and (max-width: 768px) {
      padding-bottom: 0px;
    }
  }
  p {
    margin-bottom: 0;
  }
  .contact_wrap {
    z-index: 995;
    .second_number {
      padding-left: 27px;
    }
    span {
      font-style: normal;
      padding-left: 10px;
      font-weight: 300;
      font-size: 16px;
      line-height: 24px;
      a {
        text-decoration: none;
        color: initial;
        transition: 0.3s;
        &:hover {
          font-weight: bold;
        }
      }
    }
  }
  .address_wrap {
    z-index: 995;
    span {
      font-style: normal;
      font-weight: 300;
      font-size: 16px;
      line-height: 24px;
    }
  }
  .socials_wrap {
    z-index: 995;
    /* text-align: center; */
    .icons_wrap {
      .icon {
        cursor: pointer;
      }
    }
    @media only screen and (max-width: 768px) {
      text-align: center;
    }
  }
  position: relative;
  .footer_fade_logo {
    z-index: 1;
    height: 100%;
    position: absolute;
    right: 0;
    bottom: 0;
  }
}
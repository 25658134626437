.homePage_content {
  padding-top: 85px;
  z-index: 100;
  /*
  z-index: 2;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative; */
  .texts {
    h1 {
      color: #ffffff;
      font-style: normal;
      font-weight: 400;
      font-size: 48px;
      line-height: 72px;
    }
    h3 {
      color: #ffffff;
      font-style: normal;
      font-weight: 300;
      font-size: 20px;
      line-height: 30px;
      max-width: 600px;
    }
    span {
      color: $green_darker;
    }
  }
  @media only screen and (max-width: 768px) {
    padding-top: 30px;
    .texts {
      h1 {
        color: #ffffff;
        font-style: normal;
        font-weight: 400;
        font-size: 32px;
        line-height: 48px;
      }
      h3 {
        color: #ffffff;
        font-style: normal;
        font-weight: 300;
        font-size: 18px;
        line-height: 27px;
        max-width: 600px;
      }
      span {
        color: $green_darker;
      }
    }
  }

}

.homePage_img_wrap {
  position: relative;
  img {
    width: 115%;
    height: 115%;
    position: absolute;
    top: 100px;
    right: -50px;
    z-index: 100;
    transform: translateX(80px);
  }

  @media only screen and (max-width: 991px) {
    display: none;
  }
  /* width: 100%;
  display: flex;
  justify-content: flex-end;
  img {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 50%;
  } */
}
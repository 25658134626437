.services {
  color: #ffffff;
  padding-bottom: 60px;
  .title {
    text-align: center;
    padding: 50px 0px;
    font-style: normal;
    font-weight: 400;
    font-size: 48px;
    line-height: 72px;
    @media only screen and (max-width: 768px) {
      padding-top: 50px;
      padding-bottom: 20px;
      font-size: 40px;
    }
  }
}
.serviceSubCard_background_blur {
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  position: absolute;
  left: 0;
  top: 0;
}
.serviceSubCard {
  @media only screen and (max-width: 768px) {
    margin-top: 25px;
    height: 330px;
  }
  .service_title_text{
    z-index: 50;
    position: absolute;
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 48px;
    color: #FFFFFF;
  }
  h1 {
    z-index: 50;
    position: absolute;
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 48px;
    color: #FFFFFF;
  }
  width: 100%;
  height: 430px;
  padding: 60px 30px;
  background-size: 100%;
  position: relative;
  .local_icon_button {
    position: absolute;
    bottom: 30px;
    right: 30px;
    @media only screen and (max-width: 768px) {
      right: 10px;
      bottom: 20px;
    }
  }
}
.serviceCardImg1 {
  background-image:  url("../../../images/home_background_crop.jpg");
  background-repeat: no-repeat;
  background-size: cover;

}
.serviceCardImg2 {
  background-image: url("../../../images/zateplenie.jpg");
  background-repeat: no-repeat;
  background-size: cover;
}
.serviceCardImg3 {
  background-image: url("../../../images/omietky_a_stierky.jpg");
  background-repeat: no-repeat;
  background-size: cover;
}
.serviceCardImg4 {
  background-image: url("../../../images/miarske_a_natieracske_prace.jpg");
  background-repeat: no-repeat;
  background-size: cover;
}
.serviceCardImg5 {
  background-image: url("../../../images/cistenie_a_natieranie_fasad.jpg");
  background-repeat: no-repeat;
  background-size: cover;
}
.serviceCardImg6 {
  background-image: url("../../../images/zamkova_dlazba.jpg");
  background-repeat: no-repeat;
  background-size: cover;
}